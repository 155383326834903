import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Table } from 'antd'

import Icon from '../iconfontCpt'
import { certificationStatus } from '../../utils/json/partners'

import "../../style/components/partners.scoped.scss"

const ProductsTable = ({ pageLang, products }) => {

  const [tableData, setTableData] = useState([])
  useEffect(() => {
    formatData()
  }, [products])

  function formatData() {
    if (!products || products.length === 0) {
      setTableData([])
      return
    }

    const nData = []
    products.forEach((item, idx) => {
      const versions = item.versions.map((v, i) => {
        const nItem = { ...item, ...v, rowSpan: i === 0 ? item.versions.length : 0, idx: `${idx}_${i}` }
        delete nItem.versions
        return nItem
      })
      nData.push(...versions)
    })
    setTableData(nData)
  }


  const columns = [
    {
      title: <FormattedMessage id="partners.tableCol1" />,
      dataIndex: 'company',
      key: 'company',
      width: 350,
      render: (val, row, index) => {
        const obj = {
          children: `${row[pageLang === 'en' ? 'companyEn' : 'companyZh']}${row.nShortNameEn && row.nShortNameEn === 'UniTech' ? '(' + row[pageLang === 'en' ? 'companyShortNameEn' : 'companyShortNameZh'] + ')' : ''}`,
          props: {}
        }
        if (row.rowSpan || row.rowSpan === 0) {
          obj.props.rowSpan = row.rowSpan
        }
        return obj
      }
    },
    {
      title: <FormattedMessage id="partners.tableCol2" />,
      dataIndex: 'product',
      key: 'product',
      width: 500,
      render: (val, row, index) => {
        const obj = {
          children: row[pageLang === 'en' ? 'productEn' : 'productZh'],
          props: {}
        }
        if (row.rowSpan || row.rowSpan === 0) {
          obj.props.rowSpan = row.rowSpan
        }
        return obj
      }
    },
    {
      title: <FormattedMessage id="partners.tableCol3" />,
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: <FormattedMessage id="partners.tableCol4" />,
      dataIndex: 'status',
      key: 'status',
      render: value => <div className={certificationStatus[value].className}><Icon type={certificationStatus[value].icon} size={18} /><span className="more-text">{certificationStatus[value][pageLang]}</span></div>
    }
  ];

  return (
    <div className="table-container">
      <div className="pc-title"><FormattedMessage id="partners.title" />：</div>
      <div className="pc-table"><Table rowKey="idx" dataSource={tableData} className="w-table partner-table" columns={columns} pagination={false} bordered /></div>
      {tableData.map((item, idx) =>
        <div className="mobile-table-box" key={idx}>
          <div className="m-table-title">{idx + 1}.<FormattedMessage id={`partners.tableCol1`} />：{`${item[pageLang === 'en' ? 'companyEn' : 'companyZh']}${item.nShortNameEn && item.nShortNameEn === 'UniTech' ? '(' + item[pageLang === 'en' ? 'companyShortNameEn' : 'companyShortNameZh'] + ')' : ''}`}</div>
          <div className="m-table-row">
            <div className="m-table-header"><FormattedMessage id={`partners.tableCol2`} /></div>
            <div className="m-table-content">{item[pageLang === 'en' ? 'productEn' : 'productZh']}</div>
          </div>
          <div className="m-table-row">
            <div className="m-table-header"><FormattedMessage id={`partners.tableCol3`} /></div>
            <div className="m-table-content">{item.version}</div>
          </div>
          <div className="m-table-row">
            <div className="m-table-header"><FormattedMessage id={`partners.tableCol4`} /></div>
            <div className={`${certificationStatus[item.status].className} m-table-content`}><Icon type={certificationStatus[item.status].icon} size={14} /><span className="more-text">{certificationStatus[item.status][pageLang]}</span></div>
          </div>
        </div>
      )}
      {tableData.length === 0 && <div className="nodata"><FormattedMessage id={`partners.noData`} /></div>}
    </div>
  )
}
export default ProductsTable