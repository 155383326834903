import React, { useState, useEffect } from "react"
import axios from 'axios'

import { message } from 'antd'

import Icon from '../iconfontCpt'
import ProductsTable from './productsTable'

import { formatOrignData } from '../../utils/methods'

import "../../style/components/partners.scoped.scss"

const Partners = ({ pageLang, pageType, searchKey }) => {
  // pageType: partners-page partners-home[首页不展示适配]

  const [data, setData] = useState([]) // 原始总数据

  const [tabActive, setTabActive] = useState(0)
  const [tabActiveName, setTabActiveName] = useState('')
  const [companys, setCompanys] = useState([]) // 某类型下的公司数据

  const [companyActive, setCompanyActive] = useState('')
  const [companyActiveName, setCompanyActiveName] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)

  const [allProducts, setAllProducts] = useState([]) // 全部适配数据
  const [products, setProducts] = useState([]) // 过滤后的适配数据

  useEffect(async () => {
    const res = await axios.get('https://cdn-mogdb.enmotech.com/website/partners/data.json');
    if (res && res.status === 200) {
      const { allOriginProducts, formatData } = formatOrignData(res.data || [])
      setData(formatData)
      setAllProducts(allOriginProducts)

      setTabActiveName(formatData[0] && formatData[0].typeEn)
      // 设置初始公司
      const companyList = formatData[0] && formatData[0].companyList || []
      setCompanys(companyList)
      resizeListenerFunc(companyList)
      window.addEventListener("resize", resizeListenerFunc)
    } else {
      message.error(res.data && res.data.message || 'error');
    }

    return () => {
      window.removeEventListener("resize", resizeListenerFunc)
    }
  }, [])

  // 针对搜索
  useEffect(() => {
    if (pageType === 'partners-home' || allProducts.length === 0) return
    if (searchKey) {
      // 公司、产品、版本
      const data = allProducts.filter(product => {
        const { versions, companyZh, companyEn, productZh, productEn } = product
        const versionStr = versions.map(i => i.version).join(' ').toLowerCase()
        const nSearchKey = searchKey.toLowerCase().trim()

        const arr = [versionStr, companyEn.toLowerCase(), productEn.toLowerCase(), companyZh.toLowerCase(), productZh.toLowerCase()]
        const matched = arr.find(i => i.indexOf(nSearchKey) > -1)

        return !!matched
      })
      setProducts(data)
      return
    }
    // 根据type筛选
    let results = allProducts.filter(product => product.typeEn === tabActiveName)

    // 根据公司筛选
    if (companyActiveName) {
      results = results.filter(product => product.nShortNameEn === companyActiveName || product.companyShortNameEn === companyActiveName)
    }

    setProducts(results)

  }, [allProducts, tabActiveName, companyActiveName, searchKey])

  // 不同宽度，pagesize 不同
  function resizeListenerFunc(list) {
    const width = document.body.clientWidth
    let size = list ? list.length : companys.length
    // if (width > 768) setCurrentPage(1)

    if (width <= 350) {
      size = 2
    } else if (width <= 500) {
      size = 4
    }
    else if (width <= 768) {
      size = 6
    } else {
      size = 15
    }
    if (pageSize !== size) {
      setCurrentPage(1)
      setPageSize(size)
    }
  }

  // 点击类型 -> 获取公司列表
  function changeTab(idx, item) {
    setTabActive(idx)
    setTabActiveName(item.typeEn)

    // 重置
    setCompanyActive('')
    setCompanyActiveName('')
    setCurrentPage(1)

    const companyList = data[idx] && data[idx].companyList || []
    setCompanys(companyList)
  }

  // 点击公司 -> 获取产品适配
  function clickProduct(idx, item) {
    if (pageType === 'partners-home') return
    setCompanyActive(idx)
    setCompanyActiveName(item.companyShortNameEn)
  }

  // 切换页码
  function changeCurrentPage (i) {
    setCurrentPage(i)

    setCompanyActive('')
    setCompanyActiveName('')
  }

  return (
    <div className="partners-container">
      {!searchKey && <>
        <div className={`partners-tabs-box ${pageType}`}>
          {data.map((item, idx) =>
            <div key={idx} className={`tab-item ${tabActive === idx ? 'is-active' : ''}`} onClick={() => changeTab(idx, item)}>{pageLang === 'en' ? item.typeEn : item.typeZh}</div>
          )}
        </div>
        <div className="partners-box">
          <div className="partners">
            {companys.length > pageSize &&
              <>
                <div className={`page-btn prev ${currentPage <= 1 ? 'disabled' : ''}`} onClick={() => changeCurrentPage(currentPage - 1)}><Icon type="icon-left" size={32} /></div>
                <div className={`page-btn next ${currentPage >= (companys.length / pageSize) ? 'disabled' : ''}`} onClick={() => changeCurrentPage(currentPage + 1)}><Icon type="icon-right" size={32} /></div>
              </>
            }
            {companys && companys.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item, i) => (
              <div className={`partners-item ${companyActive === i ? 'is-active' : ''}`} key={i} onClick={() => clickProduct(i, item)}>
                <div className="partners-logo" style={{ backgroundImage: `url(${item.logo})` }}></div>
                <div className="partners-name">{pageLang === 'en' ? item.companyShortNameEn : item.companyShortNameZh}</div>
              </div>
            ))}
          </div>
        </div>
      </>}
      {pageType === 'partners-page' && <ProductsTable pageLang={pageLang} products={products} />}
    </div>
  )
}
export default Partners