import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from '@reach/router'
import { Input } from 'antd'

import Icon from '../components/iconfontCpt'
import Seo from '../components/seo'
import Layout from "../components/layout"

import intl from '../intl'

import HomePartners from "../components/partners/partners"

import "../style/partner.scoped.scss"

const { Search } = Input;

const Parenter = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const location = useLocation()
  const query = location.state ? location.state.search : ''

  // 搜索关键字，tab关键字，partner关键字
  const [searchKey, setSearchKey] = useState(query || '')

  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={true}>
      <Seo title="MogDB: Partners"/>
      <div className="partners">
        <div className="partners_banner">
          <h1><FormattedMessage id="index.Partners"/></h1>
          <div className='p-search'>
            <Search
              defaultValue={searchKey}
              placeholder={intl[pageLang].index.searchContent}
              allowClear
              enterButton={<div className="search-btn"><Icon type="icon-search" size={16}/><FormattedMessage id="index.search" /></div>}
              onSearch={e => setSearchKey(e)}
              size='large'
              className="partners-search-box"
            />
          </div>
        </div>
        <div className="partners_content">
          <HomePartners pageLang={pageLang} searchKey={searchKey} pageType="partners-page" />
        </div>
      </div>
    </Layout>
  )
}
export default Parenter